import React from "react"
// import PropTypes from "prop-types"
// import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { theme } from "../components/css"
import Link from "../components/link"

const eventData = [
  {
    datestamp: "2019-09-18T10:00:00", // 2019-07-16T16:28:04+00:00
    month: "Sep",
    day: "18",
    year: "2024",
    title: "Fresno Pacific 80th Birthday",
    longDate: "Wednesday, September 18, 2024 | 10:00 a.m.",
    location: "Mainstage Theater, Warkentine Culture & Arts Center, Fresno Pacific Main Campus",
    description: `This day marks our actual birthday and we're celebrating on all our campuses! You are invited to join the celebration on our main campus as we hear from an alumni speaker, enjoy cupcakes, celebrate with Sunny and take memorable photos!`,
    link: "https://www.fresno.edu/node/4262",
  },
  {
    datestamp: "2024-10-03T00:00:00", // 2019-07-16T16:28:04+00:00
    month: "Oct",
    day: "3",
    year: "2024",
    endDate: {
      month: "Oct",
      day: "5",
      year: "2024",
    },
    title: "October 3-5 – Homecoming",
    longDate: "Thursday, October 3, 2024 – Saturday, October 5, 2024",
    location: "Fresno Pacific Main Campus",
    description: `Alumni, donors and FPU community members are invited to campus for several events as we gather together for meaningful connections: Believers Church Lecture Series, President’s Circle 80th Anniversary Celebration, Tip-Off Dinner, Choir Reunion, Sunbird volleyball game, BBQ, activities on The Green, etc.`,
    link: "https://www.fresno.edu/node/4203/",
  },
  // {
  //   datestamp: "", // 2019-07-16T16:28:04+00:00
  //   month: "",
  //   day: "",
  //   year: "",
  //   title: "Pacific Artist Series",
  //   longDate: "",
  //   location: "",
  //   description: ``,
  //   link: "",
  // },

  // {
  //   datestamp: "", // 2019-07-16T16:28:04+00:00
  //   month: "",
  //   day: "",
  //   year: "",
  //   title: "November – Dan Martin Stadium Ribbon Cutting Ceremony",
  //   longDate: "",
  //   location: "",
  //   description: ``,
  //   link: "",
  // },

  // {
  //   datestamp: "", // 2019-07-16T16:28:04+00:00
  //   month: "",
  //   day: "",
  //   year: "",
  //   title: "January – 8 Weeks of Prayer",
  //   longDate: "",
  //   location: "",
  //   description: ``,
  //   link:
  //     "",
  // },

  // {
  //   datestamp: "", // 2019-07-16T16:28:04+00:00
  //   month: "",
  //   day: "",
  //   year: "",
  //   title: "March – Sunbird Athletics Hall of Fame",
  //   longDate: "",
  //   location: "",
  //   description: ``,
  //   link:
  //     "",
  // },

  // {
  //   datestamp: "2025-05-10T10:00:00", // 2019-07-16T16:28:04+00:00
  //   month: "May",
  //   day: "10",
  //   year: "2024",
  //   title: "May 10 – 80th Commencement",
  //   longDate: "Saturday, May 10, 2025 | 10:00 a.m. – Undergraduate Ceremony | 3:00 p.m. – Graduate Ceremony",
  //   location: "10:00 a.m. – Undergraduate Ceremony",
  //   description: ``,
  //   link: "https://www.fresno.edu/node/4263/",
  // },

]

const EventContent = ({ title, date, location, link, children }) => (
  <EventContentWrapper>
    <Link to={link}>
      <EventTitle>{title}</EventTitle>
    </Link>
    <EventDate>{date}</EventDate>
    <EventLocation>{location}</EventLocation>
    <div dangerouslySetInnerHTML={{ __html: children }} />
  </EventContentWrapper>
)

const EventSidebarDate = ({ month, day, endDate }) => (
  <>
    <EventSidebarDateBubble endDate={endDate}>
      <span>{month}</span>
      <span>{day}</span>
    </EventSidebarDateBubble>
  </>
)

class Events extends React.Component {
  render() {
    return eventData.map(event => (
      <Event key={event.datestamp}>
        <EventSidebarDateWrapper>
          <EventSidebarDate month={event.month} day={event.day} />
          {event.endDate && (
            <EventSidebarDate
              month={event.endDate.month}
              day={event.endDate.day}
              endDate
            />
          )}
        </EventSidebarDateWrapper>
        <EventContent
          title={event.title}
          date={event.longDate}
          location={event.location}
          link={event.link || "https://www.fresno.edu/events"}
        >
          {event.description}
        </EventContent>
      </Event>
    ))
  }
}

export default Events

const Event = styled.div`
  display: flex;
  margin: ${theme.gutter} 0;
`
const EventSidebarDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const EventSidebarDateBubble = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  margin-top: ${theme.margin};
  margin-right: ${theme.margin};
  background-color: ${theme.c.utility.background};
  border: 2px solid ${theme.c.grey};
  border-radius: 1000px;
  text-align: center;
  font: 200 24px/27px ${theme.font.fam.univers};
  color: ${theme.c.wcag.briteblue};
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    right: -18px;
    bottom: 50%;
    width: 18px;
    border-bottom: 2px solid ${theme.c.grey};
    z-index: -1;
  }
  ${props =>
    props.endDate &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: -20px;
        right: -25px;
        width: 10px;
        height: 10px;
        transform: rotate(-45deg);
        border-left: 2px solid ${theme.c.grey};
        border-bottom: 2px solid ${theme.c.grey};
      }
    `}
  span:first-child {
    text-transform: uppercase;
  }
  span:last-child {
    font-weight: 300;
  }
`

const EventContentWrapper = styled.div`
  padding-left: calc(${theme.unit}px * 3);
  border-left: 2px solid ${theme.c.grey};
`

const EventTitle = styled.h3`
  margin-bottom: ${theme.unit}px;
  font: 200 36px/36px ${theme.font.fam.univers};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding-left: 0 !important;
  ::before {
    display: none !important;
  }
`

const EventDate = styled.div`
  font: 600 20px/26px ${theme.font.fam.univers};
  color: ${theme.c.wcag.briteblue};
  letter-spacing: 0.03rem;
`
const EventLocation = styled.div`
  margin-bottom: ${theme.unit}px;
  font: italic 300 ${theme.font.size.body} ${theme.font.fam.univers};
`
